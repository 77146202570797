var render = function () {
  var _vm$program$product;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto"
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Profil',
        href: {
          name: 'client.profile'
        }
      }, {
        label: 'Riwayat Program',
        href: {
          name: 'client.profile.programHistory'
        }
      }, {
        label: 'Detail Riwayat Program',
        isCurrent: true
      }]
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "bg": "white",
      "border": ['none', '1px solid #f2f2f2'],
      "box-shadow": ['unset', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['unset', '16px'],
      "w": "100%",
      "p": ['0px', '24px'],
      "font-family": "Roboto"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin": "auto",
      "flex-direction": "column",
      "w": "100%",
      "justify-content": "center",
      "align-content": "center",
      "p": "20px",
      "pos": "relative",
      "gap": "2rem"
    }
  }, [_c('c-heading', {
    attrs: {
      "w": ['100%', '75%'],
      "display": ['none', 'block'],
      "margin": "auto",
      "align": "left",
      "font-size": "28px",
      "font-weight": "700",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Detail Riwayat Program ")]), _c('ProgramHistoryCard', {
    key: _vm.program.id,
    attrs: {
      "title": _vm.program.name,
      "level": _vm.program.programService,
      "ahli-gizi": _vm.program.nutritionist,
      "duration": _vm.program.duration,
      "status": _vm.program.status,
      "date-start": _vm.program.startAt,
      "date-end": _vm.program.endAt,
      "price": _vm.program.productPrice,
      "coupon": _vm.program.couponName,
      "img": (_vm$program$product = _vm.program.product) === null || _vm$program$product === void 0 ? void 0 : _vm$program$product.photoUrl,
      "cursor-pointer": "unset"
    }
  }), _c('c-flex', {
    attrs: {
      "w": ['100%', '70%'],
      "margin": "auto",
      "flex-direction": "column",
      "gap": "1rem"
    }
  }, [_c('c-heading', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "align": "left",
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "font-family": "Roboto"
    }
  }, [_vm._v(" History Formulir Gizi ")]), _c('c-flex', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "padding": ['10px 12px', '30px'],
      "bg": "#FFFFFF",
      "border-radius": ['10px', '12px'],
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "cursor": "pointer",
      "justify": "space-between",
      "align-items": "center"
    },
    on: {
      "click": _vm.directQuestionnaire
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Formulir Gizi ")]), _c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "gap": ['17px', '25px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Lihat ")]), _c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "16px",
      "color": "#008C81"
    }
  })], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "w": ['100%', '70%'],
      "margin": "auto",
      "flex-direction": "column",
      "gap": "1rem"
    }
  }, [_c('c-heading', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "align": "left",
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "font-family": "Roboto"
    }
  }, [_vm._v(" History Meal Plan ")]), _c('c-flex', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "padding": ['10px 12px', '30px'],
      "bg": "#FFFFFF",
      "border-radius": ['10px', '12px'],
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "cursor": "pointer",
      "justify": "space-between",
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.getLink();
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Meal Plan ")]), _c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "gap": ['17px', '25px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Lihat ")]), _c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "16px",
      "color": "#008C81"
    }
  })], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "w": ['100%', '70%'],
      "margin": "auto",
      "flex-direction": "column",
      "gap": "1rem"
    }
  }, [_c('c-heading', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "align": "left",
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "font-family": "Roboto"
    }
  }, [_vm._v(" History Progress & Tracker ")]), _c('c-flex', {
    attrs: {
      "w": "100%",
      "margin": "auto",
      "padding": ['10px 12px', '30px'],
      "bg": "#FFFFFF",
      "border-radius": ['10px', '12px'],
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "cursor": "pointer",
      "justify": "space-between",
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.getLink();
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": "700",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Progress & Tracker ")]), _c('c-box', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "gap": ['17px', '25px']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": "#008C81",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Lihat ")]), _c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "16px",
      "color": "#008C81"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }